import { AppInstance } from '@wix/cashier-common/dist/src/types/AppInstance';
import { IWixAPI } from '@wix/yoshi-flow-editor';
import { error } from './logger';

type DecodedAppInstance = {
  appDefId: string;
  instanceId: string;
  [key: string]: any;
};

// NOTE: this method of decoding doesn't verify the signature, thus the data shouldn't be trusted
export const decodeAppInstance = (
  appInstance: AppInstance,
): DecodedAppInstance | null => {
  try {
    const base64Instance = appInstance.substring(appInstance.indexOf('.') + 1);
    const jsonStrInstance = atob(base64Instance);
    return JSON.parse(jsonStrInstance);
  } catch (e) {
    error(`Failed to decode app instance`, e);
    return null;
  }
};

export const resolveAppInstanceId = (
  appDefId: string,
  wixCodeApi: IWixAPI,
): string | undefined => {
  try {
    const consumerAppInstance = wixCodeApi.site.getAppToken!(appDefId);

    if (consumerAppInstance) {
      return decodeAppInstance(consumerAppInstance)!.instanceId;
    }
  } catch (e) {
    error(`Failed to get or decode consumer's app instance`, e);
  }
};
