import type { Describe } from 'superstruct';
import * as s from 'superstruct';
import { s_anyFunc, s_nonemptyString } from './structs';
import { WidgetPlatformProps } from '../../types/WidgetPlatformProps';
import { logPrefix } from '../logger';

const PlatformPropsStruct = s.object({
  appInstance: s_nonemptyString,
  isBlocks: s.boolean(),
  msid: s_nonemptyString,
  siteOwnerId: s_nonemptyString,
  visitorId: s.optional(s.nullable(s_nonemptyString)),

  openModal: s_anyFunc,
  requestNewAppInstance: s_anyFunc,
}) satisfies Describe<WidgetPlatformProps>;

export const validatePlatformProps = (
  platformProps: WidgetPlatformProps,
  throwIfInvalid = true,
) => {
  const [error] = s.validate(platformProps, PlatformPropsStruct);

  if (throwIfInvalid && error) {
    throw new Error(`${logPrefix} invalid platform props: ${error.message}`);
  }

  return error;
};
