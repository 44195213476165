import { filterDefined } from '@wix/cashier-common/dist/src/utils/filterDefined';
import { ControllerParams } from '@wix/yoshi-flow-editor';
import { WidgetPlatformProps } from '../types/WidgetPlatformProps';
import { blocksBaseUrl } from './blocksBaseUrl';

export const buildPlatformProps = (
  controllerParams: ControllerParams,
): WidgetPlatformProps => {
  const {
    wixCodeApi,
    platformAPIs: { bi },
    appParams,
  } = controllerParams.controllerConfig;

  return filterDefined<WidgetPlatformProps>({
    appInstance: appParams.instance,
    msid: bi!.metaSiteId,
    siteOwnerId: bi!.ownerId,
    visitorId: bi!.visitorId,
    // TODO: rewrite once Yoshi will give us a proper flag - https://jira.wixpress.com/browse/FEDINF-11060
    isBlocks: !!wixCodeApi.location.baseUrl?.startsWith(blocksBaseUrl),

    requestNewAppInstance() {
      wixCodeApi.site.loadNewSession();
    },

    openModal: (
      url: string,
      { width, height }: { width: number; height: number },
    ) => {
      wixCodeApi.window.openModal(url, { width, height });
    },
  });
};
