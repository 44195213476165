import * as s from 'superstruct';
import { AppInstance } from '@wix/cashier-common/dist/src/types/AppInstance';
import { s_appInstance } from './structs';
import { logPrefix } from '../logger';

export const validateAppInstance = (
  appInstance: AppInstance,
  throwIfInvalid = true,
) => {
  const [error] = s.validate(appInstance, s_appInstance);

  if (throwIfInvalid && error) {
    throw new Error(`${logPrefix} invalid app instance: ${error.message}`);
  }

  return error;
};
