// docs: https://docs.superstructjs.org/
import * as s from 'superstruct';
import { isCurrencyValid } from '../currencyUtils';

export const s_anyFunc = s.define<(...args: any[]) => any>(
  'any-function',
  (value) => typeof value === 'function',
);

export const s_nonemptyString = s.nonempty(s.string());

export const s_currency = s.refine(
  s.size(s.string(), 3),
  'currency',
  (value) =>
    isCurrencyValid(value) ||
    `Expected 'currency' to be 3-letter ISO 4217 code (e.g. 'USD')`,
);

export const s_appInstance = s_nonemptyString;
