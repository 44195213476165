import debug from 'debug';

if (process.env.DEBUG !== 'false') {
  debug.enable('*');
}

export const logPrefix = `$BNPL_OOI$`;

export const log = debug(logPrefix);
log.log = console.debug.bind(console);

export const error = debug(logPrefix);
