import type { Describe } from 'superstruct';
import * as s from 'superstruct';
import {
  WidgetClientProps,
  WidgetPriceProps,
  WidgetPublicClientProps,
} from '../../types/public/WidgetClientProps';
import { s_anyFunc, s_currency, s_nonemptyString } from './structs';
import { logPrefix } from '../logger';

const PricePropsStruct = s.object({
  price: s.number(),
  currency: s_currency,
}) satisfies Describe<WidgetPriceProps>;

const ClientPropsStruct = s.object({
  appDefId: s_nonemptyString,
  productId: s.optional(s_nonemptyString),

  onReady: s.optional(s_anyFunc),

  _baseUrlOverride: s.optional(s_nonemptyString),
  _debug: s.optional(s.boolean()),
}) satisfies Describe<WidgetClientProps>;

const PublicClientPropsStruct = s.assign(
  ClientPropsStruct,
  PricePropsStruct,
) satisfies Describe<WidgetPublicClientProps>;

export const validatePublicClientProps = (
  publicClientProps: WidgetPublicClientProps,
  throwIfInvalid = true,
) => {
  const [error] = s.validate(publicClientProps, PublicClientPropsStruct);

  if (throwIfInvalid && error) {
    throw new Error(
      `${logPrefix} invalid public client props: ${error.message}`,
    );
  }

  return error;
};

export const validatePriceProps = (
  priceProps: WidgetPriceProps,
  throwIfInvalid = true,
) => {
  const [error] = s.validate(priceProps, PricePropsStruct);

  if (throwIfInvalid && error) {
    throw new Error(`${logPrefix} invalid price props: ${error.message}`);
  }

  return error;
};
