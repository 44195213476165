import { CreateControllerFn } from '@wix/yoshi-flow-editor';
import {
  WidgetClientPropsEnhanced,
  WidgetPriceProps,
  WidgetPublicClientProps,
} from '../../types/public/WidgetClientProps';
import { appDefId } from '../../utils/metaInfo';
import { filterDefined } from '@wix/cashier-common/dist/src/utils/filterDefined';
import { WidgetRootProps } from '../../types/WidgetRootProps';
import { buildPlatformProps } from '../../utils/buildPlatformProps';
import {
  validatePriceProps,
  validatePublicClientProps,
} from '../../utils/validate/validatePublicProps';
import { logPrefix, log } from '../../utils/logger';
import { validateAppInstance } from '../../utils/validate/validateAppInstance';
import { WidgetAPI } from '../../types/public/WidgetAPI';
import { validatePlatformProps } from '../../utils/validate/validatePlatformProps';
import { resolveAppInstanceId } from '../../utils/appInstanceUtils';
import { ControllerDevConfig } from '../../types/ControllerDevConfig';

log(`controller module executed`);

const createController = (async (
  controllerParams,
  _cfg?: ControllerDevConfig, // used for testing/storybook
) => {
  const { enableValidations = true } = _cfg || {};
  const { controllerConfig } = controllerParams;
  const { wixCodeApi } = controllerConfig;
  const setProps = (props: Partial<WidgetRootProps>) =>
    controllerConfig.setProps(props);
  const publicApi: WidgetAPI = {
    configure: (props: WidgetPublicClientProps) => {
      log(`configure() called with %o`, props);

      if (enableValidations) {
        validatePublicClientProps(props);
      }

      const appInstanceId = resolveAppInstanceId(props.appDefId, wixCodeApi)!;
      const { price, currency, ...restClientProps } = props;

      setProps({
        clientProps: filterDefined<WidgetClientPropsEnhanced>({
          ...restClientProps,
          appInstanceId,
          ...(!!restClientProps.onReady && {
            onReady: (result) => {
              log(`calling onReady with result: %o`, result);
              return restClientProps.onReady!(result);
            },
          }),
        }),
        priceProps: { price, currency },
      });
    },
    updatePrice: (priceProps: WidgetPriceProps) => {
      log(`updatePrice() called with %o`, priceProps);

      if (enableValidations) {
        validatePriceProps(priceProps);
      }

      setProps({ priceProps });
    },
  };

  return {
    pageReady() {
      log(`pageReady executed`);
      const platformProps = buildPlatformProps(controllerParams);

      if (enableValidations) {
        validatePlatformProps(platformProps);
      }

      setProps({ platformProps });

      wixCodeApi.site.onInstanceChanged(({ instance }) => {
        if (enableValidations) {
          const error = validateAppInstance(instance, false);

          if (error) {
            throw new Error(
              `${logPrefix} invalid appInstance received in onInstanceChanged ${error.message}`,
            );
          }
        }

        platformProps.appInstance = instance;
        setProps({ platformProps });
      }, appDefId);

      // uncomment to test standalone Banner OOI in Editor
      // !!!!!!! DO NOT COMMIT THIS CODE UNCOMMENTED !!!!!!!!
      // if (controllerParams.flowAPI.environment.isEditor) {
      //   log(
      //     `faking configure() call to show real Banner in Editor for development purposes`,
      //   );
      //   publicApi.configure({
      //     appDefId: 'df892fe9-626f-44c9-a328-e29f93880b38',
      //     price: 100,
      //     currency: 'USD',
      //   });
      // }
    },
    exports: (): WidgetAPI => publicApi,
  };
}) satisfies CreateControllerFn;

export default createController;
